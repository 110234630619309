<template>
  <div>
    <vx-card>
      <div class="flex items-center justify-center mb-4">
        <img
          class="mr-3"
          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/network-plus/279928282177077248/leaf-icon.png"
          alt="Logo"
        />
        <h1 class="font-bold">Vacate (HQ-2) | San Jose, CA</h1>
      </div>

      <div class="flex items-center justify-center mb-8">
        <img
          style="max-width: 1200px"
          class="responsive"
          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/network-plus/279928282177077248/figure.png"
          alt="Diagram"
        />
      </div>

      <div class="flex flex-wrap items-center justify-center mb-8">
        <div class="flex items-center mr-6 mb-2" v-for="item in inputs">
          <h2 class="font-bold mr-3" style="color: #78dd40">{{ item.name }}</h2>
          <vs-input
            v-model="item.value"
            :success="item.marked === true"
            :danger="item.marked === false"
            val-icon-pack="feather"
            val-icon-success="icon-check-circle"
            val-icon-danger="icon-x-circle"
            style="max-width: 130px"
          />
        </div>
      </div>

      <div class="flex items-center justify-center">
        <h2 class="font-bold mr-3" style="color: #78dd40">{{ remaining.name }}</h2>
        <vs-input
          v-model="remaining.value"
          :success="remaining.marked === true"
          :danger="remaining.marked === false"
          val-icon-pack="feather"
          val-icon-success="icon-check-circle"
          val-icon-danger="icon-x-circle"
          style="max-width: 130px"
        />
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      inputs: [
        {
          name: 'IFD-01',
          value: '/',
          answer: '/28',
          marked: null,
        },
        {
          name: 'HRD-01',
          value: '/',
          answer: '/27',
          marked: null,
        },
        {
          name: 'TRD-01',
          value: '/',
          answer: '/26',
          marked: null,
        },
        {
          name: 'CSD-01',
          value: '/',
          answer: '/26',
          marked: null,
        },
        {
          name: 'RLD-01',
          value: '/',
          answer: '/28',
          marked: null,
        },
      ],

      remaining: {
        name: 'Remaining IPs Subnet',
        value: '/',
        answer: '/26',
        marked: null,
      },
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      this.inputs.forEach((input, index) => {
        if (input.value === input.answer) {
          this.inputs[index].marked = true;
          totalScore++;
        } else {
          this.inputs[index].marked = false;
        }
      });

      if (this.remaining.value === this.remaining.answer) {
        this.remaining.marked = true;
        totalScore++;
      } else {
        this.remaining.marked = false;
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }
      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
